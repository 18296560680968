import consumer from "./consumer"
var RpiChannel;
// window.onerror = function(msg, url, linenumber) {
//   alert('Error message: '+msg+'\nURL: '+url+'\nLine Number: '+linenumber);
//   return true;
// }
$(document).ready(function() {
  console.log("Jquery ready")
  var screen_uuid = $(".rpi_screen_uuid").val()
  console.log(screen_uuid)
  if(screen_uuid && screen_uuid.length > 0) {
    console.log("connect")
    RpiChannel = consumer.subscriptions.create({ channel: "RpiChannel", screen_uuid: screen_uuid, from: "SERVER" }, {
      connected() {
        console.log("connected")
        // Called when the subscription is ready for use on the server
      },
    
      disconnected() {
        // Called when the subscription has been terminated by the server
      },
    
      received(data) {
        console.log("Incoming Data")
        console.log(data)
        if(data["from"] == "RPI") {
          if(data["params"]["title"] == "Internet") {
            var isOnline = data["params"]["value"]
            if(isOnline) {
              $(".internet_status").html("Online")
              $(".internet_status").removeClass("is-danger").addClass("is-success")
            }
            else {
              $(".internet_status").html("Offline")
              $(".internet_status").removeClass("is-success").addClass("is-danger")
            }
            
          }
          if(data["params"]["title"] == "ScreenShot") {
            $(".screenshotimage").attr("src", data["params"]["value"])
          }
          if(data["params"]["title"] == "Pong") {
            alert("Pong")
          }
        }
        // Called when there's incoming data on the websocket for this channel
      },
      message_from_server(data) {
        this.perform('message_from_server', data)
      },
    });

    $(".screen_on").click(function() {
      RpiChannel.message_from_server({ from: "SERVER", params: {title: "ScreenPower", value: true}})
    })
    $(".screen_off").click(function() {
      RpiChannel.message_from_server({ from: "SERVER", params: {title: "ScreenPower", value: false}})
    })

    $(".config_on").click(function() {
      RpiChannel.message_from_server({ from: "SERVER", params: {title: "ConfigMode", value: true}})
    })
    $(".config_off").click(function() {
      RpiChannel.message_from_server({ from: "SERVER", params: {title: "ConfigMode", value: false}})
    })
    $(".screen_info").click(function() {
      RpiChannel.message_from_server({ from: "SERVER", params: {title: "ScreenInfo", value: $(this).data('screen-info')}})
    })
    $(".screenshot").click(function() {
      RpiChannel.message_from_server({ from: "SERVER", params: {title: "ScreenShot", value: true}})
    })
    $(".ping").click(function() {
      RpiChannel.message_from_server({ from: "SERVER", params: {title: "Ping", value: true}})
    })
    $(".window_refresh").click(function() {
      RpiChannel.message_from_server({ from: "SERVER", params: {title: "WindowRefresh", value: true}})
    })

    
  }
})


