
$(document).ready(function () {
  bulmaSlider.attach();
  bulmaQuickview.attach();
  bulmaCalendar.attach('.bulma-date-picker', {
    type: 'date',
    dateFormat: 'dd/MM/yyyy'
  });

  $(".notification .delete").click(function() {
    $(this).parent().remove();
  });
  setTimeout(function() {
    $(".notice.notification").slideUp();
  }, 2000);

  $('#tabs li').on('click', function() {
    var tab = $(this).data('tab');

    $('#tabs li').removeClass('is-active');
    $(this).addClass('is-active');

    $('#tab-content div').removeClass('is-active');
    $('div[data-content="' + tab + '"]').addClass('is-active');
  });

  $('.pincode_checkall').change(function() {
    var checked = $(this).prop('checked');
    $(this).closest('.pincode_checkboxes').find('.pincode_checkbox').prop('checked', checked);
    // $('.pincode_checkbox').prop('checked', checked);
  });
  $('.select-all-checkbox').change(function() {
    var checked = $(this).prop('checked');
    $(this).closest('.individiual').find('.individual-checkbox').prop('checked', checked);
  });

  

});
